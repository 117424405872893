import dynamic from 'next/dynamic';

export default {
    ArticlePage: dynamic(() => import('./ArticlePage')),
    AuthorPage: dynamic(() => import('./AuthorPage')),
    BasePage: dynamic(() => import('./BasePage')),
    BookmarkPage: dynamic(() => import('./BookmarkPage')),
    CampaignPage: dynamic(() => import('./CampaignPage')),
    HomePage: dynamic(() => import('./HomePage')),
    LandingPage: dynamic(() => import('./LandingPage')),
    MapPage: dynamic(() => import('./MapPage'), { ssr: false }),
    NotFoundPage: dynamic(() => import('./NotFoundPage')),
    PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
    PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
    SearchPage: dynamic(() => import('./SearchPage')),
};
